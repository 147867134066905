import { OrganizationResponseDto } from "@/lib/interfaces/organizations";
import { convertHtmlToEditorState } from "../../lib/utils/convertHtml";
import { Flex, Button, Box, Text, Heading } from "@chakra-ui/react";
import { Save } from "lucide-react";
import React, { useState } from "react";
import { Editor, EditorState } from "react-draft-wysiwyg";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import useApiRequest from "../../lib/hooks/useRequest";

const PrivacyPolicy = ({ organization }: { organization: OrganizationResponseDto }) => {
  const defaultContent = organization.privacyPolicyTemplate ?? ""; // Your default content as HTML
  const [editorNewState, setEditorNewState] = useState(() => convertHtmlToEditorState(defaultContent));
  const { apiRequest } = useApiRequest();

  const onEditorNewStateChange = (newState: EditorState) => {
    setEditorNewState(newState);
  };

  const save = async () => {
    const contentState = editorNewState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);

    // Convert raw content to plain text
    const htmlContent = draftToHtml(rawContentState);
    const req = {
      privacyPolicyTemplate: htmlContent,
    };

    await apiRequest("organizations", "PATCH", {
      body: req,
    });
  };

  return (
    <Flex className="p-8 rounded border border-gray-300 gap-8 flex-col h-full bg-white">
      <Flex className="gap-8 mb-6">
        <Box className="w-2/5">
          <Heading size="sm" className="font-bold mb-4">
            Ihre Datenschutzerklärung
          </Heading>
          <Text>
            Die Datenschutzerklärung erläutert den Umgang mit personenbezogenen Daten im Bewerbungsverfahren und
            informiert über Rechte der Bewerber gemäß Datenschutzgesetzen.
          </Text>
        </Box>
        <Box className="w-3/5">
          <Editor
            editorState={editorNewState}
            wrapperClassName="editorWrapper"
            editorClassName="editorContent"
            onEditorStateChange={onEditorNewStateChange}
          />
        </Box>
      </Flex>
      <Flex className="gap-4 justify-end mt-auto">
        <Button
          colorScheme="primary"
          color="white"
          className="flex gap-2 p-2 text-white"
          variant="solid"
          onClick={save}
        >
          <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
            <Save />
          </Box>
          <span>Speichern</span>
        </Button>
      </Flex>
    </Flex>
  );
};

export default PrivacyPolicy;
