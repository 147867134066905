import { Button, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Invitation } from "../../../components/invitation/Invitation";
import { Context, ErrorEntity } from "../../../components/root/context";
import SubscriptionModal from "../../../components/subscriptions/Subscription";
import { parseEntity } from "../../../lib/utils/entityParser";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "./dialog";

const ErrorModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const { error, setError } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();
  const [rerenderInvite, setRerenderInvite] = useState<boolean>();

  const onClose = (withoutReload?: boolean) => {
    setIsOpen(false);
    if (setError) {
      setError(undefined);
    }
    if (withoutReload === undefined || withoutReload) {
      return;
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (error) {
      if (error.entity === ErrorEntity.CheckInvite) {
        setIsInviteOpen(true);
        setRerenderInvite(true);
      } else if (
        error.entity === ErrorEntity.EMailNotVerifiedForbidden ||
        error.entity === ErrorEntity.MFANotAuthorizedForbidden
      ) {
        setRerenderInvite(true);
        navigate(
          error.entity === ErrorEntity.EMailNotVerifiedForbidden
            ? `/verification/mail?mail=${error.mail}`
            : `/verification/2fa?mail=${error.mail}`
        );
        setIsOpen(false);
        setError && setError(undefined);
      } else {
        setIsOpen(true);
        setRerenderInvite(true);
      }
    }
  }, [error, navigate, setError]);

  useEffect(() => {
    if (!error && isOpen) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (rerenderInvite) {
      setRerenderInvite(undefined);
    }
  }, [rerenderInvite]);

  return (
    <>
      {error && error.entity !== ErrorEntity.CheckInvite && (
        <Dialog
          open={isOpen}
          onOpenChange={(value) => {
            setIsOpen(value);
            if (value === false) {
              onClose(!error.withReload);
            }
          }}
        >
          {error.entity !== ErrorEntity.NoSubscriptionForbidden ? (
            <DialogContent
              onInteractOutside={(e) => {
                if (error.isClosable === false) {
                  e.preventDefault();
                  return;
                }
                onClose(!error.withReload);
              }}
              isClosable={error.isClosable}
            >
              <DialogHeader>
                <DialogTitle>
                  {error.entity === ErrorEntity.NoUserFoundForbidden
                    ? "Perfekt. Es sind noch kleine Schritte notwendig um dein Profil zu vervollständigen."
                    : error.entity === ErrorEntity.NoOrganizationForbidden
                    ? "Ein letzter Schritt"
                    : "Es ist ein Fehler aufgetreten"}
                </DialogTitle>
              </DialogHeader>
              <div>
                <Text>{error.message}</Text>
              </div>
              <DialogFooter>
                <div className={`flex gap-3 w-full`}>
                  {error.isClosable && (
                    <>
                      <Button
                        colorScheme="blue"
                        className={error.actionLocation ? "grow" : ""}
                        variant="outline"
                        onClick={() => onClose(!error.withReload)}
                      >
                        Schließen
                      </Button>
                    </>
                  )}
                  {error.actionLocation && (
                    <Link to={error.actionLocation} className="grow flex">
                      <Button colorScheme="blue" className="grow" onClick={() => onClose(true)}>{`${parseEntity(
                        error.entity
                      )}`}</Button>
                    </Link>
                  )}
                </div>
              </DialogFooter>
            </DialogContent>
          ) : (
            <SubscriptionModal onClose={onClose} />
          )}
        </Dialog>
      )}
      {isInviteOpen && rerenderInvite === undefined && (
        <Invitation isInviteOpen={isInviteOpen} setInviteOpen={setIsInviteOpen} />
      )}
    </>
  );
};

export default ErrorModal;
