import { CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Heading, Spinner, Text } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { EditorState, Modifier, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { CheckIcon, Save, X } from "lucide-react";
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { useUser } from "../../hooks/use-user";
import useApiRequest from "../../lib/hooks/useRequest";
import {
  ApplicantCreationEntitiesByCompanyLocationIdResponseDto,
  ApplicantCreationEntitiesResponseDto,
  ApplicantDetailsResponseDto,
  ApplicantLogEvent,
  ApplicantMailTemplateWithValuesResponseDto,
  ApplicantMailTemplatesResponseDto,
  CreateApplicantRequestDto,
  Genders,
  JobOfferSelectableEntityResponseDto,
  ValutationEnum,
} from "../../lib/interfaces/applicant";
import { convertHtmlToEditorState } from "../../lib/utils/convertHtml";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../ui/components/ui/form";
import { Input } from "../../ui/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../ui/components/ui/select";
import { Textarea } from "../../ui/components/ui/textarea";
import SendIcon from "../../ui/icons/SendIcon";
import Placeholder from "../email-dispatch/Placeholder";

const ApplicantEditor = () => {
  const [item, setItem] = useState<ApplicantDetailsResponseDto>();
  const [creationEntities, setCreationEntities] = useState<ApplicantCreationEntitiesResponseDto>();
  const { id } = useParams();
  const { apiRequest } = useApiRequest();

  const getItem = useCallback(async () => {
    const response = await apiRequest<ApplicantDetailsResponseDto>(`applicants/${id}`, "GET");

    if (response.data) {
      setItem(response.data);
      setCreationEntities(response.data.selectableEntities);
    }
  }, [apiRequest, id]);

  const getEntities = useCallback(async () => {
    const creationEntitiesResponse = await apiRequest<ApplicantCreationEntitiesResponseDto>(
      "applicants/creation-entities",
      "GET"
    );
    if (creationEntitiesResponse.data) {
      setCreationEntities(creationEntitiesResponse.data);
    }
  }, [apiRequest]);

  useEffect(() => {
    // if there is an id, get the item
    if (id) {
      getItem();
    } else {
      getEntities();
    }
  }, [getEntities, getItem, id]);

  return (
    <Flex direction="column" padding="1rem 1.5rem" className="h-full">
      <Flex width="100%" justifyContent="space-between" margin="0 0 1.5em">
        <Heading size="lg">
          <Text as="span" className="font-normal">
            Übersicht der Bewerber
          </Text>{" "}
          &gt;{" "}
          <Text as="span" className="font-semibold">
            {id ? "Bewerber bearbeiten" : "Bewerber hinzufügen"}
          </Text>
        </Heading>
      </Flex>
      <Flex className="px-8 py-4 rounded border border-gray-300 gap-24 h-full bg-white">
        {creationEntities && (
          <>
            {id ? (
              item && <PositionForm item={item} creationEntities={creationEntities} />
            ) : (
              <PositionForm creationEntities={creationEntities} />
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
};

const PositionForm = ({
  creationEntities,
  item,
}: {
  creationEntities: ApplicantCreationEntitiesResponseDto;
  item?: ApplicantDetailsResponseDto;
}) => {
  const { apiRequest } = useApiRequest();
  const [selectedLocationsJobOffers, setSelectedLocationsJobOffers] = useState<JobOfferSelectableEntityResponseDto[]>(
    creationEntities.jobOffers
  );
  const { user } = useUser();
  const isEditable =
    (user?.permissionCompanyLocations === "FULL" ||
      user?.permissionCompanyLocations === "WRITE" ||
      user?.permissionOrganizationAdmin) &&
    (item ? item.isEditable : true);

  const [jobOffersLoading, setJobOffersLoading] = useState(false);
  const [emailIsOpen, setEmailIsOpen] = useState(false);

  const telRegex = /^\+?(\d+|\([0-9]+\))([0-9]*[-()]?){5,13}\d$/;
  // const telRegex = /^\+(\d{1,2}|\(\d{1,2}\))(\d[-()]?){10,11}\d$/;

  const navigate = useNavigate();

  const formSchema = z.object({
    name: z.string().min(3, {
      message: "Bitte geben Sie Ihren Namen ein",
    }),
    gender: z.string().optional(),
    street: z.string().optional(),
    postCode: z.string().optional(),
    placeName: z.string().optional(),
    experience: z.string().optional(),
    schoolQualification: z.string().optional(),
    professionalTraining: z.string().optional(),
    phone: z.union([
      z.literal(""),
      z
        .string()
        .trim()
        .transform((val) => val.replace(/\s/g, ""))
        .refine((val) => telRegex.test(val), { message: "Bitte geben Sie Ihre Telefonnummer ein" }),
    ]),
    mail: z.union([z.literal(""), z.string().email("Bitte geben Sie eine E-Mail Adresse ein")]),
    contactAvailability: z.string().optional(),
    notes: z.string().optional(),
    belongsToCompanyLocationId: user?.permittedContentWithoutCompanyLocationAssignment
      ? z.string()
      : z.string().min(1, {
          message: "Bitte wählen Sie einen Standort aus",
        }),
    belongsToJobOfferId: z.string(),
    valuation: z.string().optional(),
    source: z.string().min(3, {
      message: "Bitte geben Sie Ihre Quelle ein",
    }),
    status: z.string().min(3, {
      message: "Bitte geben Sie Ihren Status ein",
    }),
    applicationDate: z.string().min(3, {
      message: "Bitte geben Sie Ihr Bewerbungsdatum ein",
    }),
    todo: z.string(),
  });

  const getJobOffersByLocationId = useCallback(
    async (id: string | undefined) => {
      if (id) {
        setJobOffersLoading(true);
        const res = await apiRequest<ApplicantCreationEntitiesByCompanyLocationIdResponseDto>(
          `applicants/creation-entities/companyLocation/${id}`,
          "GET"
        );
        if (res.data) {
          setSelectedLocationsJobOffers(res.data.jobOffers);
          setJobOffersLoading(false);
        }
      }
    },
    [apiRequest]
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      todo: item?.todo ?? "",
      name: item?.name ?? "",
      gender: item?.gender ?? "NONE",
      street: item?.street ?? "",
      postCode: item?.postCode ?? "",
      placeName: item?.placeName ?? "",
      experience: item?.experience ?? "",
      schoolQualification: item?.schoolQualification ?? "",
      professionalTraining: item?.professionalTraining ?? "",
      phone: item?.phone ?? "",
      mail: item?.mail ?? "",
      contactAvailability: item?.contactAvailability ?? "",
      notes: item?.notes ?? "",
      belongsToCompanyLocationId: item?.belongsToCompanyLocationId ?? "",
      belongsToJobOfferId: item?.belongsToJobOfferId ?? "",
      valuation: item?.valuation ?? "",
      source: item?.belongsToSource ?? "",
      status: item?.belongsToStatus ?? creationEntities.status.find((status) => status.default)?.id ?? "",
      applicationDate: item?.applicationDate ?? "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const req: CreateApplicantRequestDto = {
      name: values.name,
      gender: values.gender === "" ? "" : values.gender,
      street: values.street,
      postCode: values.postCode,
      placeName: values.placeName,
      experience: values.experience,
      schoolQualification: values.schoolQualification,
      professionalTraining: values.professionalTraining,
      phone: values.phone,
      mail: values.mail === "" ? null : values.mail,
      contactAvailability: values.contactAvailability,
      notes: values.notes,
      belongsToCompanyLocationId:
        values.belongsToCompanyLocationId === "undefined" ? "" : values.belongsToCompanyLocationId,
      // ...(values.belongsToCompanyLocationId ? { belongsToCompanyLocationId: values.belongsToCompanyLocationId } : {}),
      belongsToJobOfferId: values.belongsToJobOfferId === "undefined" ? "" : values.belongsToJobOfferId,
      valuation: values.valuation === "" ? undefined : (values.valuation as ValutationEnum),
      belongsToSource: values.source,
      belongsToStatus: values.status,
      applicationDate: values.applicationDate,
      todo: values.todo,
    };

    let response;

    if (item) {
      response = await apiRequest(`applicants/${item._id}`, "PATCH", {
        body: req,
        toast: { toastText: "Bewerber wurde erfolgreich aktualisiert" },
      });
    } else {
      response = await apiRequest("applicants", "POST", {
        body: req,
        toast: { toastText: "Bewerber wurde erfolgreich erstellt" },
      });
    }

    if (!response.error) {
      navigate("/bewerber");
    }
  }

  useEffect(() => {
    if (item?.belongsToCompanyLocationId) {
      getJobOffersByLocationId(item.belongsToCompanyLocationId);
    }
  }, [getJobOffersByLocationId, item?.belongsToCompanyLocationId]);

  const getLogsString = () => {
    let final = "";
    if (item?.applicantLogs) {
      item.applicantLogs.forEach((log) => {
        let eventDescription = ApplicantLogEvent[log.event as unknown as keyof typeof ApplicantLogEvent].replaceAll(
          "{INFORMATION}",
          log.information ?? ""
        );
        if (log.information) {
          eventDescription = `${eventDescription} - ${log.information}`;
        }
        final += `${format(new Date(log.createdAt), "dd.MM.yyyy HH:mm")} - ${eventDescription}\n`;
      });
    }
    return final;
  };

  return (
    <Form {...form}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit(onSubmit)();
        }}
        className="w-full flex gap-16 h-full bg-white"
      >
        <Flex className="gap-24 h-full grow">
          <Box className="w-1/2">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="flex gap-4 mb-4 w-full">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                    Name<span className="text-primary">*</span>
                  </FormLabel>

                  <Box className="w-full">
                    <FormControl>
                      <Input placeholder="Name" {...field} disabled={!isEditable} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="gender"
              render={({ field }) => (
                <FormItem className="flex gap-4 mb-4 w-full">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">Geschlecht</FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Select
                        onValueChange={(value) => {
                          field.onChange(value);
                        }}
                        defaultValue={field.value}
                        disabled={!isEditable}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Wählen Sie ein Geschlecht aus" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {creationEntities?.genders.map((gender) => (
                            <SelectItem key={gender} value={gender}>
                              {Genders[gender as unknown as keyof typeof Genders]}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="street"
              render={({ field }) => (
                <FormItem className="flex gap-4 mb-4 w-full">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">Straße</FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Input placeholder="Straße" {...field} disabled={!isEditable} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="postCode"
              render={({ field }) => (
                <FormItem className="flex gap-4 mb-4 w-full">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">Postleitzahl</FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Input placeholder="Postleitzahl" {...field} disabled={!isEditable} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="placeName"
              render={({ field }) => (
                <FormItem className="flex gap-4 mb-4 w-full">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">Wohnort</FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Input placeholder="Wohnort" {...field} disabled={!isEditable} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
            {!item ? (
              <>
                <FormField
                  control={form.control}
                  name="belongsToCompanyLocationId"
                  render={({ field }) => (
                    <FormItem className="flex gap-4 mb-4 w-full">
                      <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                        Standort
                        {!user?.permittedContentWithoutCompanyLocationAssignment && (
                          <span className="text-primary">*</span>
                        )}
                      </FormLabel>
                      <Box className="w-full">
                        <FormControl>
                          <Select
                            onValueChange={(value) => {
                              field.onChange(value);
                              if (value !== "undefined") {
                                getJobOffersByLocationId(value);
                              } else {
                                setSelectedLocationsJobOffers([]);
                              }
                            }}
                            defaultValue={
                              field.value || user?.permittedContentWithoutCompanyLocationAssignment
                                ? "undefined"
                                : undefined
                            }
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Wähle den Standort aus" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {user?.permittedContentWithoutCompanyLocationAssignment && (
                                <SelectItem key={`-1`} value="undefined">
                                  Kein Standort ausgewählt
                                </SelectItem>
                              )}
                              {creationEntities?.companyLocations.map((location) => (
                                <SelectItem key={location._id} value={location._id}>
                                  {`${location.value}${location.isArchive ? " - Archiviert" : ""}`}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </Box>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="belongsToJobOfferId"
                  render={({ field }) => (
                    <FormItem className="flex gap-4 mb-4 w-full">
                      <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">Beworben als</FormLabel>
                      <Box className="w-full">
                        {jobOffersLoading ? (
                          <Spinner />
                        ) : (
                          <>
                            <Select onValueChange={field.onChange} defaultValue={field.value || "undefined"}>
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue placeholder="Wählen Sie das Jobangebot aus" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {user?.permittedContentWithoutCompanyLocationAssignment && (
                                  <SelectItem key={`-1`} value="undefined">
                                    Kein Jobangebot ausgewählt
                                  </SelectItem>
                                )}
                                {selectedLocationsJobOffers?.map((jobOffer) => (
                                  <SelectItem key={jobOffer._id} value={jobOffer._id}>
                                    {`${jobOffer.value}${jobOffer.isArchive ? " - Archiviert" : ""}`}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </>
                        )}
                      </Box>
                    </FormItem>
                  )}
                />
              </>
            ) : (
              <>
                <FormField
                  control={form.control}
                  name="belongsToCompanyLocationId"
                  render={({ field }) => (
                    <FormItem className="flex gap-4 mb-4 w-full">
                      <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">Standort</FormLabel>
                      <Box className="w-full">
                        <Input
                          placeholder="Beworben als"
                          value={item.companyLocationTitle ?? "Kein Standort ausgewählt"}
                          disabled
                        />
                      </Box>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="belongsToJobOfferId"
                  render={({ field }) => (
                    <FormItem className="flex gap-4 mb-4 w-full">
                      <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">Beworben als</FormLabel>
                      <Box className="w-full">
                        <Input
                          placeholder="Beworben als"
                          value={item.jobOfferTitle ?? "Kein Jobangebot ausgewählt"}
                          disabled
                        />
                      </Box>
                    </FormItem>
                  )}
                />
              </>
            )}
            <FormField
              control={form.control}
              name="experience"
              render={({ field }) => (
                <FormItem className="flex gap-4 mb-4 w-full">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">Berufserfahrung</FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Input placeholder="Erfahrung" {...field} disabled={!isEditable} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="schoolQualification"
              render={({ field }) => (
                <FormItem className="flex gap-4 mb-4 w-full">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">Schulabschluss</FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Input placeholder="Schulabschluss" {...field} disabled={!isEditable} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="professionalTraining"
              render={({ field }) => (
                <FormItem className="flex gap-4 mb-4 w-full">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">Berufsausbildung</FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Input placeholder="Berufsausbildung" {...field} disabled={!isEditable} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="contactAvailability"
              render={({ field }) => (
                <FormItem className="flex gap-4 mb-4 w-full">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">Erreichbarkeit</FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Input placeholder="Erreichbarkeit" {...field} disabled={!isEditable} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
            {item ? (
              <FormField
                control={form.control}
                name="source"
                render={({ field }) => (
                  <FormItem className="flex gap-4 mb-4 w-full">
                    <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                      Quelle<span className="text-primary">*</span>
                    </FormLabel>
                    <Box className="w-full">
                      <Input placeholder="Quelle" value={item.sourceTitle} disabled />
                    </Box>
                  </FormItem>
                )}
              />
            ) : (
              <FormField
                control={form.control}
                name="source"
                render={({ field }) => (
                  <FormItem className="flex gap-4 mb-4 w-full">
                    <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                      Quelle<span className="text-primary">*</span>
                    </FormLabel>
                    <Box className="w-full">
                      <Select onValueChange={field.onChange} defaultValue={field.value} disabled={item ? true : false}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Wählen Sie die Quelle aus" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {creationEntities?.sources.map((source, index) => (
                            <SelectItem key={index} value={source.id}>
                              {source.value === "None" ? "Keine Quelle ausgewählt" : source.value}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </Box>
                  </FormItem>
                )}
              />
            )}
          </Box>
          <Box className="w-1/2 h-full flex flex-col justify-between">
            <FormField
              control={form.control}
              name="applicationDate"
              render={({ field }) => {
                const { value, ...restField } = field;
                return (
                  <FormItem className="flex gap-4 mb-4 w-full">
                    <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                      Bewerbungsdatum<span className="text-primary">*</span>
                    </FormLabel>
                    <Box className="w-full">
                      <FormControl>
                        <Input
                          placeholder="Bewerbungsdatum"
                          type="date"
                          {...restField}
                          value={value.split("T")[0]}
                          max={new Date().toISOString().split("T")[0]}
                          disabled={item ? true : false}
                        />
                      </FormControl>
                      <FormMessage />
                    </Box>
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="status"
              render={({ field }) => (
                <FormItem className="flex gap-4 mb-4 w-full">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                    Status der Bewerbung<span className="text-primary">*</span>
                  </FormLabel>
                  <Box className="w-full">
                    <Select
                      disabled={!isEditable}
                      onValueChange={field.onChange}
                      defaultValue={
                        field.value
                          ? field.value
                          : creationEntities?.status?.find((status) => status.default)?.id ?? undefined
                      }
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Wählen Sie den Status aus" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {creationEntities?.status.map((status) => (
                          <SelectItem key={status.id} value={status.id}>
                            {status.value}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="valuation"
              render={({ field }) => (
                <FormItem className="flex gap-4 mb-4 w-full">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">Bewertung</FormLabel>
                  <Box className="w-full">
                    <Select onValueChange={field.onChange} defaultValue={field.value} disabled={!isEditable}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Wählen Sie die Bewertung aus" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {Object.entries(ValutationEnum).map(([key, value]) => (
                          <SelectItem key={key} value={key}>
                            {value}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem className="flex gap-4 mb-4 w-full">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">Telefon</FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Input placeholder="Telefon" {...field} disabled={!isEditable} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="mail"
              render={({ field }) => (
                <FormItem className="flex gap-4 mb-4 w-full">
                  <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">Email</FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Input placeholder="Email" {...field} disabled={!isEditable} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="notes"
              render={({ field }) => (
                <FormItem className="flex flex-col w-full">
                  <FormLabel className="w-full font-bold mt-3 text-md">Notizen</FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Textarea rows={5} placeholder="Notizen" {...field} disabled={!isEditable} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="todo"
              render={({ field }) => (
                <FormItem className="flex flex-col w-full">
                  <FormLabel className="w-full font-bold mt-3 text-md">ToDos</FormLabel>
                  <Box className="w-full">
                    <FormControl>
                      <Textarea rows={5} placeholder="ToDos" {...field} disabled={!isEditable} />
                    </FormControl>
                    <FormMessage />
                  </Box>
                </FormItem>
              )}
            />

            {item && (
              <>
                <FormField
                  control={form.control}
                  name="notes"
                  render={() => (
                    <FormItem className="flex flex-col w-full mb-3">
                      <FormLabel className="w-full font-bold mt-3 text-md">Verlauf</FormLabel>
                      <Box className="w-full">
                        <FormControl>
                          <Textarea rows={5} placeholder="Verlauf" value={getLogsString()} disabled />
                        </FormControl>
                        <FormMessage />
                      </Box>
                    </FormItem>
                  )}
                />
              </>
            )}

            <Flex className="gap-4 justify-end mt-auto">
              {item && (
                <>
                  <Button
                    colorScheme="primary"
                    color="white"
                    className="flex gap-2 p-2 text-white ml-auto"
                    variant="solid"
                    isDisabled={!isEditable}
                    onClick={() => setEmailIsOpen(true)}
                  >
                    <Box boxSize={5} display="flex" alignItems="center" justifyContent="center" fill="white">
                      <SendIcon />
                    </Box>
                    <span>Email senden</span>
                  </Button>
                  {emailIsOpen && <MailDialog item={item} isOpen={emailIsOpen} setEmailIsOpen={setEmailIsOpen} />}
                </>
              )}
              <Button
                colorScheme="primary"
                color="white"
                className="flex gap-2 p-2 text-white"
                variant="solid"
                type="submit"
                isDisabled={form.formState.isSubmitting || (item ? !item.isEditable : false)}
              >
                <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
                  <Save />
                </Box>
                <span>Speichern</span>
              </Button>
              <Button
                colorScheme="primary"
                className="flex gap-2 p-2 text-white"
                variant="outline"
                onClick={() => {
                  navigate("/bewerber");
                }}
              >
                <Box boxSize={5} display="flex" alignItems="center" justifyContent="center">
                  <X />
                </Box>
                <span>Schließen</span>
              </Button>
            </Flex>
          </Box>
        </Flex>
      </form>
    </Form>
  );
};

const MailDialog = ({
  item,
  isOpen,
  setEmailIsOpen,
}: {
  item: ApplicantDetailsResponseDto;
  isOpen: boolean;
  setEmailIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { apiRequest } = useApiRequest();
  const [mailTemplates, setMailTemplates] = useState<ApplicantMailTemplatesResponseDto>();

  const getMailTemplates = useCallback(async () => {
    const response = await apiRequest<ApplicantMailTemplatesResponseDto>(
      `applicants/${item._id}/mails/mail-templates`,
      "GET"
    );
    if (response.data) {
      setMailTemplates(response.data);
    } else {
      setEmailIsOpen(false);
    }
  }, [apiRequest, item._id, setEmailIsOpen]);

  useEffect(() => {
    getMailTemplates();
  }, [getMailTemplates]);

  return (
    <Dialog onOpenChange={(value) => setEmailIsOpen(value)} open={isOpen}>
      {mailTemplates && (
        <DialogContent className="min-w-[750px]">
          <MailModal applicantId={item._id} mailTemplates={mailTemplates} />
        </DialogContent>
      )}
    </Dialog>
  );
};

const MailModal = ({
  applicantId,
  mailTemplates,
}: {
  applicantId: string;
  mailTemplates: ApplicantMailTemplatesResponseDto;
}) => {
  enum Step {
    SELECT_TEMPLATE,
    EDIT_TEMPLATE,
  }
  const [editorState, setEditorState] = useState(() => convertHtmlToEditorState(""));
  const [step, setStep] = useState<Step>(Step.SELECT_TEMPLATE);
  const [selectedTemplate, setSelectedTemplate] = useState<ApplicantMailTemplateWithValuesResponseDto>();
  const [error, setError] = useState<string | null>(null);
  const debounceTimer = useRef<number | undefined>(undefined);
  const [mailIsValid, setMailIsValid] = useState<boolean>();
  const [mailCheckLoading, setMailCheckLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const { apiRequest } = useApiRequest();

  const checkMailValidity = useCallback(
    async (mail: string) => {
      setMailCheckLoading(true);
      const response = await apiRequest<{ isAvailable: boolean }>(
        `company-locations/mail-validation?mailSenderPrefix=${mail}`,
        "GET"
      );
      if (response.data) {
        setMailIsValid(response.data.isAvailable);
      }
      setMailCheckLoading(false);
    },
    [apiRequest]
  );

  const formSchema = z.object({
    subject: z.string().min(3, {
      message: "Bitte geben Sie den Betreff ein",
    }),
    mailTemplate: z.object({
      _id: z.string(),
      title: z.string(),
    }),
    body: z.string(),
    fromName: z.string().min(3, {
      message: "Bitte geben Sie Ihren Namen ein",
    }),
    fromMail: z
      .string()
      .min(3, {
        message: "Bitte geben Sie Ihren Email Prefix ein",
      })
      .refine(
        (value) => {
          if (!value) {
            return true;
          }
          const mailRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !mailRegex.test(value);
        },
        { message: "Bitte geben Sie keine Email Adresse an" }
      ),
    replyToAddress: z.string().email("Bitte geben Sie eine E-Mail Adresse ein"),
    replyToName: z.string().min(3, {
      message: "Bitte geben Sie Ihren Namen ein",
    }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      subject: "",
      body: "",
      fromMail: "",
      fromName: "",
      replyToAddress: "",
      replyToName: "",
    },
  });

  useEffect(() => {
    if (mailIsValid === false) {
      form.setError("fromMail", {
        message: "Email-Adresse ist schon vergeben",
      });
    } else {
      form.clearErrors("fromMail");
    }
  }, [form, mailIsValid]);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);

    // Convert raw content to plain text
    const htmlContent = draftToHtml(rawContentState);

    const req: {
      subject: string;
      text: string;
      from?: {
        name: string;
        addressPrefix: string;
      };
      replyTo?: {
        name: string;
        address: string;
      };
    } = {
      subject: values.subject,
      text: htmlContent,
    };

    if (values.fromName && values.fromMail) {
      req.from = {
        name: values.fromName,
        addressPrefix: values.fromMail,
      };
    }

    if (values.replyToName && values.replyToAddress) {
      req.replyTo = {
        name: values.replyToName,
        address: values.replyToAddress,
      };
    }

    const response = await apiRequest<string>(`applicants/${applicantId}/mail/${selectedTemplate?._id}`, "POST", {
      body: req,
      toast: { toastText: "Email wurde erfolgreich gesendet" },
    });

    if (response.error) {
      setError(response.error as string);
    } else {
      setError(null);
      form.reset();
      window.location.reload();
    }
  };

  const replacePlaceholders = useCallback(
    (text?: string, templateHard?: ApplicantMailTemplateWithValuesResponseDto) => {
      if ((selectedTemplate && selectedTemplate.template) || templateHard) {
        const template = selectedTemplate ? selectedTemplate : templateHard;
        if (!template) {
          return "";
        }
        if (text) {
          let replaced = text;
          const placeholders = template.placeholders;
          placeholders.forEach((placeholder) => {
            replaced = replaced.replaceAll(`[${placeholder.placeholder}]`, placeholder.value);
          });
          console.log(replaced);
          return replaced;
        } else {
          let replacedHtml = template.template;
          const placeholders = template.placeholders;
          placeholders.forEach((placeholder) => {
            replacedHtml = replacedHtml?.replaceAll(`[${placeholder.placeholder}]`, placeholder.value);
          });
          return replacedHtml;
        }
      }
      return "";
    },
    [selectedTemplate]
  );

  const getTemplate = useCallback(
    async (templateId: string) => {
      const response = await apiRequest<ApplicantMailTemplateWithValuesResponseDto>(
        `applicants/${applicantId}/mails/mail-templates/${templateId}`,
        "GET"
      );
      if (response.data) {
        const templateWithId = { ...response.data, _id: templateId === "none" ? "" : templateId };
        setSelectedTemplate(templateWithId);
        form.setValue("subject", replacePlaceholders(response.data.subject, templateWithId));
        if (response.data.from) {
          form.setValue("fromName", response.data.from.name);
          form.setValue("fromMail", response.data.from.addressPrefix);
        }
        if (response.data.replyTo) {
          form.setValue("replyToName", response.data.replyTo.name);
          form.setValue("replyToAddress", response.data.replyTo.address);
        }
        checkMailValidity(response.data.from?.addressPrefix ?? "");
      }
      return response.data;
    },
    [apiRequest, applicantId, checkMailValidity, form, replacePlaceholders]
  );

  useEffect(() => {
    if (selectedTemplate) {
      setEditorState(convertHtmlToEditorState(replacePlaceholders()));
      setStep(Step.EDIT_TEMPLATE);
    }
  }, [Step.EDIT_TEMPLATE, replacePlaceholders, selectedTemplate]);

  return (
    <>
      <DialogHeader>
        <DialogTitle>Mail senden</DialogTitle>
      </DialogHeader>
      {mailTemplates && (
        <Form {...form}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              form.handleSubmit(onSubmit)(e);
            }}
            className="w-full gap-16 h-full bg-white"
          >
            <div>
              <Flex className="gap-24 h-full w-full grow">
                <Box className="w-full">
                  {step === Step.SELECT_TEMPLATE ? (
                    <>
                      <FormField
                        control={form.control}
                        name="mailTemplate"
                        render={({ field }) => (
                          <FormItem className="flex gap-4 mb-4 w-full">
                            <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">Email Vorlage</FormLabel>
                            <Box className="w-full">
                              <FormControl>
                                <Select
                                  onValueChange={async (value) => {
                                    const template = await getTemplate(value);
                                    field.onChange({
                                      _id: value,
                                      title: template?.title ?? "",
                                    });
                                  }}
                                  defaultValue={field.value?._id ?? ""}
                                >
                                  <FormControl>
                                    <SelectTrigger>
                                      <SelectValue placeholder="Wählen Sie die Email Vorlage aus" />
                                    </SelectTrigger>
                                  </FormControl>
                                  <SelectContent>
                                    <SelectItem value="none" className="cursor-pointer" key="-1">
                                      Kein Template benutzen
                                    </SelectItem>
                                    <hr />
                                    {mailTemplates.labels.map((label) => (
                                      <SelectGroup key={label._id}>
                                        <hr />
                                        <SelectLabel className="font-bold">{label.value}</SelectLabel>
                                        {label.mailTemplates.map((template) => (
                                          <SelectItem
                                            key={template.value}
                                            value={template._id}
                                            className="cursor-pointer"
                                          >
                                            {template.value}
                                          </SelectItem>
                                        ))}
                                      </SelectGroup>
                                    ))}
                                  </SelectContent>
                                </Select>
                              </FormControl>
                              <FormMessage />
                            </Box>
                          </FormItem>
                        )}
                      />
                    </>
                  ) : (
                    <>
                      <Text className="font-bold mt-4">{selectedTemplate?.title}</Text>
                      <hr />
                      <FormField
                        control={form.control}
                        name="subject"
                        render={({ field }) => (
                          <FormItem className="flex gap-4 mb-4 w-full">
                            <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                              Betreff<span className="text-primary">*</span>
                            </FormLabel>
                            <Box className="w-full">
                              <FormControl>
                                <Input
                                  placeholder="Betreff"
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(replacePlaceholders(e.target.value));
                                  }}
                                />
                              </FormControl>
                              <FormMessage />
                            </Box>
                          </FormItem>
                        )}
                      />
                      <div>
                        <h3 className="font-bold mt-4">Von:</h3>
                        <div className="ml-4">
                          <FormField
                            control={form.control}
                            name="fromName"
                            render={({ field }) => (
                              <FormItem className="flex gap-4 mb-4 w-full">
                                <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                                  Name<span className="text-primary">*</span>
                                </FormLabel>
                                <Box className="w-full">
                                  <FormControl>
                                    <Input placeholder="Kandidat.io" {...field} />
                                  </FormControl>
                                  <FormMessage />
                                </Box>
                              </FormItem>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name="fromMail"
                            render={({ field }) => (
                              <FormItem className="flex gap-4 mb-4 w-full">
                                <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                                  Email<span className="text-primary">*</span>
                                </FormLabel>
                                <Box className="w-full">
                                  <FormControl>
                                    <Flex className="w-full gap-2 items-center">
                                      <Input
                                        placeholder="no-reply"
                                        {...field}
                                        onChange={(e) => {
                                          field.onChange(e);
                                          if (debounceTimer.current !== undefined) {
                                            clearTimeout(debounceTimer.current);
                                          }

                                          debounceTimer.current = window.setTimeout(() => {
                                            checkMailValidity(e.target.value);
                                          }, 500);
                                        }}
                                      />
                                      <Text className="mr-2">@kandidat.io</Text>
                                      {mailCheckLoading && <Spinner />}
                                      {mailIsValid && !mailCheckLoading && <CheckIcon className="text-green-500" />}
                                      {mailIsValid === false && !mailCheckLoading && (
                                        <CloseIcon className="text-red-500" />
                                      )}
                                    </Flex>
                                  </FormControl>
                                  <FormMessage />
                                </Box>
                              </FormItem>
                            )}
                          />
                        </div>
                      </div>
                      <div>
                        <h3 className="font-bold mt-4">Antwort an:</h3>
                        <div className="ml-4">
                          <FormField
                            control={form.control}
                            name="replyToName"
                            render={({ field }) => (
                              <FormItem className="flex gap-4 mb-4 w-full">
                                <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                                  Name<span className="text-primary">*</span>
                                </FormLabel>
                                <Box className="w-full">
                                  <FormControl>
                                    <Input placeholder="Kandidat.io" {...field} />
                                  </FormControl>
                                  <FormMessage />
                                </Box>
                              </FormItem>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name="replyToAddress"
                            render={({ field }) => (
                              <FormItem className="flex gap-4 mb-4 w-full">
                                <FormLabel className="max-w-[186px] w-full font-bold mt-3 text-md">
                                  Email<span className="text-primary">*</span>
                                </FormLabel>
                                <Box className="w-full">
                                  <FormControl>
                                    <Flex className="w-full gap-2 items-center">
                                      <Input placeholder="no-reply" {...field} />
                                    </Flex>
                                  </FormControl>
                                  <FormMessage />
                                </Box>
                              </FormItem>
                            )}
                          />
                        </div>
                      </div>
                      <FormField
                        control={form.control}
                        name="body"
                        render={({ field }) => (
                          <FormItem className="flex flex-col gap-3 w-full">
                            <FormLabel className="w-full font-bold mt-4">
                              Arbeitgebervorteile / benefits<span className="text-primary">*</span>
                            </FormLabel>
                            <Box className="w-full">
                              <FormControl>
                                <>
                                  <Placeholder
                                    placeholders={
                                      selectedTemplate?.placeholders.map((parent) => parent.placeholder) ?? []
                                    }
                                    onPlaceholderClicked={(placeholder) => {
                                      const contentState = editorState.getCurrentContent();
                                      const selectionState = editorState.getSelection();

                                      const newContentState = Modifier.insertText(
                                        contentState,
                                        selectionState,
                                        selectedTemplate?.placeholders.find((p) => p.placeholder === placeholder)
                                          ?.value ?? `[${placeholder}]`
                                      );

                                      const newEditorState = EditorState.push(
                                        editorState,
                                        newContentState,
                                        "insert-characters"
                                      );
                                      setEditorState(newEditorState);
                                    }}
                                  />
                                  <Editor
                                    editorState={editorState}
                                    wrapperClassName="settingsEditorWrapper"
                                    editorClassName="settingsEditorContent"
                                    onEditorStateChange={setEditorState}
                                  />
                                </>
                              </FormControl>
                              <FormMessage />
                            </Box>
                          </FormItem>
                        )}
                      />
                    </>
                  )}
                </Box>
              </Flex>
            </div>
            <DialogFooter>
              <div className="flex flex-col gap-2">
                <Flex gap="1em" className="w-fit ml-auto">
                  <Button
                    colorScheme="primary"
                    className={`flex gap-2 p-2 text-white ml-auto mt-4`}
                    hidden={step === Step.SELECT_TEMPLATE}
                    variant="outline"
                    onClick={() => {
                      setStep(Step.SELECT_TEMPLATE);
                    }}
                  >
                    <span>Zurück</span>
                  </Button>
                  <DialogClose asChild>
                    <Button
                      colorScheme="primary"
                      className={`flex gap-2 p-2 text-white ml-auto mt-4`}
                      hidden={step === Step.EDIT_TEMPLATE}
                      variant="outline"
                      id="close-btn"
                    >
                      <span>Zurück</span>
                    </Button>
                  </DialogClose>
                  {step === Step.EDIT_TEMPLATE && (
                    <>
                      {isConfirmed ? (
                        <Button
                          colorScheme="primary"
                          color="white"
                          className="flex gap-2 p-2 text-white ml-auto mt-4"
                          variant="solid"
                          onClick={async () => {
                            if (!mailIsValid) {
                              return;
                            }
                            const result = await form.trigger();
                            if (result) {
                              onSubmit(form.getValues());
                            }
                          }}
                        >
                          <span>Senden</span>
                        </Button>
                      ) : (
                        <Button
                          colorScheme="primary"
                          color="white"
                          className="flex gap-2 p-2 text-white ml-auto mt-4"
                          variant="solid"
                          onClick={async () => {
                            setIsConfirmed(true);
                          }}
                        >
                          <span>Alle eingaben korrekt?</span>
                        </Button>
                      )}
                    </>
                  )}
                </Flex>
                {error && <Text color="red">{error}</Text>}
              </div>
            </DialogFooter>
          </form>
        </Form>
      )}
    </>
  );
};

export default ApplicantEditor;
